import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';
import { ApiResponse, AuthToken } from 'types/interface';
import * as https from 'https';
import { clearAllAuthCookies, setAuthTokenCookies } from 'utils/AuthUtil';
import { BASE_URL } from 'utils/Config'; // TODO: link to API base
import { AuthApi } from './AuthApi';
import { RefreshTokenExpiredError } from '../../libs/error';

const isDev = process.env.NODE_ENV === 'development';

class Api {
  baseURL: string;

  client: AxiosInstance;

  token: AuthToken;

  constructor() {
    this.baseURL = BASE_URL;
    this.client = axios.create({
      baseURL: this.baseURL,
      headers: {
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        httpsAgent: new https.Agent({ keepAlive: true }),
        withCredentials: true,
      },
      timeout: 20000,
    });

    this.client.interceptors.request.use(config => {
      const { token } = this;
      if (token?.accessToken) {
        this.setAuthHeader(config, token.accessToken);
      }
      return config;
    });

    this.client.interceptors.response.use(
      response => response,
      async error => {
        const originalRequest = error.config;
        if (error.response?.status === 401) {
          let prevRefreshToken;
          if (this.isServer()) {
            // FIXME 메모리에 올리지 않은 refresh token 을 가져다 쓸 수 있는 방법은??
            prevRefreshToken = this.getRefreshTokenInHeader(originalRequest);
          } else {
            prevRefreshToken = this.getToken()?.refreshToken;
          }

          if (prevRefreshToken) {
            if (!this.isTokenExpired(prevRefreshToken)) {
              try {
                const { accessToken, refreshToken } = await AuthApi.refreshTokens(prevRefreshToken);
                this.setAuthHeader(originalRequest, accessToken);

                if (this.isServer()) {
                  this.setRefreshTokenInHeader(originalRequest, refreshToken);
                } else {
                  this.setToken({
                    accessToken,
                    refreshToken,
                  });
                  setAuthTokenCookies(accessToken, refreshToken);
                }
                return this.client(originalRequest);
              } catch (e) {
                return Promise.reject(e);
              }
            }
            if (this.isTokenExpired(this.token.refreshToken)) {
              if (this.isServer()) {
                throw new RefreshTokenExpiredError();
              } else {
                this.setToken(null);
                clearAllAuthCookies();
              }
            }
          }
        }
        return Promise.reject(error);
      },
    );
    axiosRetry(this.client, { retries: 3 });
  }

  isServer = () => typeof window === 'undefined';

  buildAuthHeader = (accessToken: string) => `Bearer ${accessToken}`;

  // TODO: server context 를 메모리에 올려서 header 사용 안하고 쿠키에서 통일해서 처리할 수 잇는지 고려
  setAuthHeader = (requestConfig: AxiosRequestConfig, accessToken: string) => {
    // eslint-disable-next-line no-param-reassign
    requestConfig.headers = {
      ...requestConfig.headers,
      Authorization: this.buildAuthHeader(accessToken),
    };
  };

  setReferrer = (referrer: string) => {
    this.client.defaults.headers.common['X-Referer'] = referrer;
  };

  setToken = (authToken: AuthToken | null) => {
    this.token = authToken;
  };

  getToken = () => this.token;

  setRefreshTokenInHeader = (config: AxiosRequestConfig, refreshToken: string) => {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      ...config.headers,
      'X-Refresh-Token': refreshToken,
    };
  };

  getRefreshTokenInHeader = (config: AxiosRequestConfig) => config.headers['X-Refresh-Token'];

  isTokenExpired = token => {
    const payloadBase64 = token.split('.')[1];
    const decodedJson = Buffer.from(payloadBase64, 'base64').toString();
    const decoded = JSON.parse(decodedJson);
    const { exp } = decoded;
    return Date.now() >= exp * 1000;
  };

  logMessage(message?: any, ...optionalParams: any[]) {
    if (isDev) {
      console.log(message, optionalParams);
    }
  }

  logError(message?: any, ...optionalParams: any[]) {
    console.error(message, optionalParams);
  }

  public async get<R>(url: string, options?: AxiosRequestConfig): Promise<ApiResponse<R | null>> {
    try {
      const res = await this.client.get<ApiResponse<R>>(url, options);
      this.logMessage('[GET] API SUCESS', res);
      return res.data;
    } catch (e) {
      this.logError('[GET] API ERROR:', e);
      throw e;
    }
  }

  public async post<R>(
    url: string,
    data: Record<string, any>,
    options?: AxiosRequestConfig,
  ): Promise<ApiResponse<R | null>> {
    try {
      const res = await this.client.post<ApiResponse<R>>(url, data, options);
      this.logMessage(`[POST] ${url} API SUCESS`, res);

      return res.data;
    } catch (e) {
      this.logError(`[POST] ${url} API ERROR:`, e, e.response?.status);
      throw e;
    }
  }

  public async patch<R>(
    url: string,
    data: Record<string, any>,
    options?: AxiosRequestConfig,
  ): Promise<ApiResponse<R | null>> {
    try {
      const res = await this.client.patch<ApiResponse<R>>(url, data, options);
      this.logMessage(`[PATCH] ${url} API SUCESS`, res);

      return res.data;
    } catch (e) {
      this.logError(`[PATCH] ${url} API ERROR:`, e, e.response?.status);
      throw e;
    }
  }

  public async delete(url: string, options?: AxiosRequestConfig): Promise<void> {
    try {
      const res = await this.client.delete(url, options);
      this.logMessage(`[DELETE] ${url} API SUCESS`, res);
    } catch (e) {
      this.logError(`[DELETE] ${url} API ERROR:`, e, e.response?.status);
      throw e;
    }
  }
}

const BaseApiModule = new Api();

export { BaseApiModule as BaseApi };
