export enum Colors {
  mainBackground = '#F0F2F6',
  primary = '#00BC87',
  process1 = '#058479',
  process2 = '#0B4668',
  process3 = '#0F0F5A',
  errorRed = '#C51E1E',
  imminentRed = '#D01010',
  trendingGreen = '#22A967',
  mainGreenOverlay = 'rgba(0, 188, 135, 0.15)',
  secondary = '#140958',
  gray11 = '#111111',
  gray24 = '#242424',
  gray3d = '#3d3d3d',
  gray40 = '#404040',
  gray59 = '#595959',
  gray66 = '#666666',
  gray7d = '#7d7d7d',
  gray82 = '#828282',
  gray89 = '#898989',
  gray99 = '#999999',
  grayaa = '#aaaaaa',
  grayc4 = '#c4c4c4',
  grayd2 = '#d2d2d2',
  graydd = '#dddddd',
  grayee = '#eeeeee',
  grayf0 = '#f0f0f0',
  grayf6 = '#f6f6f6',
  grayf7 = '#f7f7f7',
  grayf8 = '#f8f8f8',
  bluishGray82 = '#828286',
  bluishGrayda = '#DADCE0',
  bluishGraye3 = '#E3E5E7',
  categoryLove = '#EC5A51',
  categoryMovie = '#2947B2',
  categoryCareer = '#47525D',
  categoryLifestyle = '#4CAB61',
  categoryPhilosophy = '#F8A224',
  eventMain = '#FFB549',
  categoryMusic = '#39BEA6',
  categoryRelationship = '#A775DA',
  categoryWriting = '#6792FF',
  paleWhite = '#FFFFFF',
  meetupTypeBlue = '#269BFF',
  nfygOrange = '#E25345',
  nfygGray = '#667085',
  nfygNeutralNavy = '#475667',
  nfygNavy = '#344054',
  nfygBlack = '#101828',
  nfygDimGray = '#98A2B3',
  nfygBlue = '#4B8BF5',
  nfygGreen = '#38B277',
  dimYellow = '#FED880',
  wine = '#841A1A',
  newCategoryLove = '#E25345',
  newCategoryMovie = '#354272',
  newCategoryCareer = '#303030',
  newCategoryLifestyle = '#4CAB61',
  newCategoryPhilosophy = '#F8A224',
  newCategoryMusic = '#39B7A0',
  newCategoryRelationship = '#8C78A9',
  newCategoryWriting = '#5071AC',
  tentOrangeGradient = 'linear-gradient(to bottom right, #ffbd3f 0%, #ff9b22 72%, #ff8910 100%)',
}
