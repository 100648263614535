import { User, AuthResponse, AuthToken, AuthOTPResponse } from 'types/interface';
import { BaseApi } from './BaseApi';

interface IsRegisteredResponse {
  registered: boolean;
}

export interface RegisterUserRequestBody extends Partial<User> {
  provider?: string;
  providerToken?: string;
}

export interface UpdateUserRequestBody extends Partial<User> {
  firebaseToken?: string;
  marketingPreferenceBookmarkToOpenNotification?: boolean;
  marketingPreferenceBookmarkToReopeningRequest?: boolean;
  marketingPreferenceBookmarkToWaitlist?: boolean;
  marketingPreferenceWeeklyPromotionCrm?: boolean;
}

class AuthApi {
  updateUser = async (userId: string, body: UpdateUserRequestBody) => {
    const res = await BaseApi.patch<AuthResponse>(`/v2/nfyg/users/${userId}`, body);
    return res.data;
  };

  registerUser = async (body: RegisterUserRequestBody) => {
    const res = await BaseApi.post<AuthResponse>('/v2/nfyg/users', body);
    return res.data;
  };

  signIn = async (provider: string, providerToken: string) => {
    // Interceptor 없는 클라이언트 사용해야만함
    const res = await BaseApi.post<AuthResponse>('/v2/nfyg/token/generate', {
      provider,
      providerToken,
    });
    return res.data;
  };

  refreshTokens = async (refreshToken: string) => {
    const res = await BaseApi.post<AuthToken>('/v2/nfyg/token/refresh', { refreshToken });
    return res.data;
  };

  getOTP = async (firebaseToken: string, phoneNumber: string) => {
    const res = await BaseApi.get<AuthOTPResponse>('/v2/nfyg/auth/otp', {
      params: { firebaseToken, phoneNumber },
    });
    return res.data;
  };

  verifyOTP = async (firebaseToken: string, otp: string, phoneNumber: string) => {
    const res = await BaseApi.post<AuthOTPResponse>('/v2/nfyg/auth/otp', {
      firebaseToken,
      otp,
      phoneNumber,
    });
    return res.data;
  };

  withdrawalUser = async (userId: string): Promise<void> => {
    await BaseApi.delete(`/v2/nfyg/users/${userId}`);
  };
}

const AuthApiClass = new AuthApi();
export { AuthApiClass as AuthApi };
