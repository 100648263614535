import {
  WishResponse,
  WishType,
  WishedMeetupResponse,
  MeetupType,
  WishCountResponse,
  PaginationRequest,
  AuthToken,
} from 'types/interface';
import { AxiosRequestConfig } from 'axios';
import { BaseApi } from './BaseApi';

interface WishRequestBody {
  meetupId?: string;
  hostId?: string;
  type: WishType;
}
class WishApi {
  createWish = async (body: WishRequestBody, auth?: AuthToken) => {
    const requestConfig: AxiosRequestConfig = {};
    if (auth?.accessToken) {
      BaseApi.setAuthHeader(requestConfig, auth.accessToken);
    }
    if (auth?.refreshToken) {
      BaseApi.setRefreshTokenInHeader(requestConfig, auth.refreshToken);
    }
    const res = await BaseApi.post<WishResponse>('/v2/nfyg/wishes', body, requestConfig);
    return res.data;
  };

  getWish = async (data: { meetupId?: string; hostId?: string; type: WishType }) => {
    const res = await BaseApi.get<WishResponse>('/v2/nfyg/wishes', { params: data });
    return res.data;
  };

  deleteWish = async (data: { meetupId?: string; hostId?: string; type: WishType }) => {
    await BaseApi.delete('/v2/nfyg/wishes', { params: data });
  };

  getWishedMeetups = async (
    wishTypes: WishType[],
    meetupTypes: MeetupType[],
    available?: boolean,
    order?: string,
    pagination: PaginationRequest = { limit: 300, offset: 0 },
    auth?: AuthToken,
  ) => {
    const requestConfig: AxiosRequestConfig = {
      params: {
        ...pagination,
        available,
        meetupType: meetupTypes && meetupTypes.join(','),
        order,
        wishType: wishTypes.join(','),
      },
    };
    if (auth?.accessToken) {
      BaseApi.setAuthHeader(requestConfig, auth.accessToken);
    }
    if (auth?.refreshToken) {
      BaseApi.setRefreshTokenInHeader(requestConfig, auth.refreshToken);
    }
    const res = await BaseApi.get<WishedMeetupResponse>('/v2/nfyg/wished-meetups', requestConfig);
    return res.data;
  };

  getWishCount = async (
    type: WishType,
    userId?: string,
    meetupType?: number[],
    meetupId?: string,
    hostId?: string,
    auth?: AuthToken,
  ) => {
    const requestConfig: AxiosRequestConfig = {
      params: {
        hostId,
        meetupId,
        meetupType: meetupType?.join(','),
        type,
        userId,
      },
    };
    if (auth?.accessToken) {
      BaseApi.setAuthHeader(requestConfig, auth.accessToken);
    }
    if (auth?.refreshToken) {
      BaseApi.setRefreshTokenInHeader(requestConfig, auth.refreshToken);
    }
    const res = await BaseApi.get<WishCountResponse>('/v2/nfyg/wishes/count', requestConfig);
    return res.data;
  };
}

const WishApiClass = new WishApi();
export { WishApiClass as WishApi };
