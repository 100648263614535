import { getAnalytics, setAnalyticsCollectionEnabled, logEvent } from "firebase/analytics";

import { Analytics } from "@firebase/analytics";
import mixpanel from "mixpanel-browser";
import * as process from "process";
import { Meetup } from "types/interface";
import { mapKeys } from "lodash";
import { convertMeetupTypeToEng } from "utils/MeetupUtil";
import { initializeFirebase } from "./Firebase/initialize";

const app = initializeFirebase();
let firebaseAnalytics: Analytics;

export enum LogScreens {
  MAIN = "main",
  MEETUP_DETAIL = "meetups",
  SALON_LIST = "salons",
  EVENT_LIST = "events",
  COMING_SOON_SALON_LIST = "salons/coming-soon",
  SALON_CURATION_LIST = "salons/curation",
  SALON_COMMUNITY = "community/salons",
  ATTEND = "attend",
  REGISTER = "register",
  MY = "my",
  BOOKMARK = "bookmark",
  COMMUNITY = "community",
}

export enum LogActions {
  CLICK = "click",
  KEYPRESS = "keypress",
  SWIPE = "swipe",
  VIEW = "view",
}

export enum LoginPlatforms {
  KAKAO = "kakao",
  GOOGLE = "google",
}

export enum MixpanelEvents {
  VIEW_MY_PAGE = "View My Page",
  SIGNUP_STARTED = "Signup Started",
  SIGNUP_COMPLETED = "Signup Completed", // 서버 사이드 트래킹
  VIEW_MEETUP_DETAIL_PAGE = "View Meetup Detail Page",
  VIEW_SALON_LIST_PAGE = "View Salon List Page",
  VIEW_COMING_SOON_SALON_LIST_PAGE = "View Coming Soon Salon List Page",
  VIEW_EVENT_LIST_PAGE = "View Event List Page",
  VIEW_GATHERING_LIST_PAGE = "View Gathering List Page",
  // CLICK_MEETUP_MEMEBR_REGISTER_BUTTON = 'Click Meetup Member Register Button', - 해당 페이지 이동이 중요하지 클릭 자체는 의미가 적지 않나?
  MEETUP_MEMBER_REGISTRATION_STARTED = "Meetup Member Registration Started",
  MEETUP_VISIT_REGISTRATION_STARTED = "Meetup Visit Registration Started",
  CREDIT_CARD_REGISTRATION_STARTED = "Credit Card Registration Started", // 첫 카드인지 확인 필요
  CREDIT_CARD_REGISTRATION_COMPLETED = "Credit Card Registration Completed", // 서버 사이드 트래킹
  REGISTERED_CREDIT_CARD_DELETED = "Registered Credit Card Deleted", // 서버 사이드 트래킹
  MEETUP_MEMBER_REGISTRATION_COMPLETED = "Meetup Member Registration Completed", // 서버 사이드 트래킹
  MEETUP_VISITOR_REGISTRATION_COMPLETED = "Meetup Visitor Registration Completed", // 서버 사이드 트래킹
  WAITLIST_NOTIFICATION_SUBSCRIBED = "Waitlist Notification Subscribed", // 서버 사이드 트래킹
  WAITLIST_NOTIFICATION_SUBSCRIPTION_CANCELED = "Waitlist Notification Subscription Canceled", // 서버 사이드 트래킹
  REOPENING_REQUESTED = "Reopening Requested", // 서버 사이드 트래킹
  REOPENING_REQUEST_CANCELED = "Reopening Request Canceled", // 서버 사이드 트래킹
  BOOKMARK_ADDED = "Bookmark Added", // 서버 사이드 트래킹
  BOOKMARK_REMOVED = "Bookmark Removed", // 서버 사이드 트래킹
  OPEN_NOTIFICATION_SUBSCRIBED = "Open Notification Subscribed", // 서버 사이드 트래킹
  OPEN_NOTIFICATION_SUBSCRIPTION_CANCELED = "Open Notification Subscription Canceled", // 서버 사이드 트래킹
}

export const initAnalytics = () => {
  // TODO: auth 처리 하는 곳이서 tracking setUser 처리
  if (process.env.NODE_ENV === "production") {
    firebaseAnalytics = getAnalytics(app);
    setAnalyticsCollectionEnabled(firebaseAnalytics, true);

    const ReactPixel = require("react-facebook-pixel").default;
    const pixelOptions = {
      autoConfig: true,
      debug: false
    };
    ReactPixel.init("431385180921522", undefined, pixelOptions);
    ReactPixel.pageView();
  }
};

export const getFirebaseAnalytics = () => {
  if (!firebaseAnalytics) {
    initAnalytics();
  }
  return firebaseAnalytics;
};

export const logEventAction = (
  screen: string,
  action: string,
  target: string,
  params: Record<string, any> = {}
) => {
  const eventName = `${screen}_${action}_${target}`;

  // TODO: purchase 관련해서는 firebase, react 결제 관련 이벤트 잘 로깅, 전환 측정 위해서
  if (process.env.BUILD_ENV === "production") {
    logEvent(getFirebaseAnalytics(), eventName, {
      ...params
    });

    const ReactPixel = require("react-facebook-pixel").default;
    ReactPixel.trackCustom(eventName, params);
  } else {
    console.log(`%c [Analytics]: ${eventName}`, "background: #fff, font-weight: bold", params);
  }
};

export enum LogPurchaseProcessTargets {
  SIGN_UP = "sign_up",
  BEGIN_CHECKOUT = "begin_checkout",
  ADD_PAYMENT_INFO = "add_payment_info",
  PURCHASE = "purchase",
}

export const logPurchaseEventAction = (target: LogPurchaseProcessTargets, price?: number) => {
  let firebaseEventName;
  let pixelEventName;
  switch (target) {
    case LogPurchaseProcessTargets.SIGN_UP:
      firebaseEventName = "sign_up";
      pixelEventName = "CompleteRegistration";
      break;
    case LogPurchaseProcessTargets.BEGIN_CHECKOUT:
      firebaseEventName = "begin_checkout";
      pixelEventName = "InitiateCheckout";
      break;
    case LogPurchaseProcessTargets.ADD_PAYMENT_INFO:
      firebaseEventName = "add_payment_info";
      pixelEventName = "AddPaymentInfo";
      break;
    case LogPurchaseProcessTargets.PURCHASE:
      firebaseEventName = "purchase";
      pixelEventName = "Purchase";
      break;
    default:
      return;
  }

  let params;
  if (price) {
    params = {
      currency: "KRW",
      value: price
    };
  }

  if (process.env.BUILD_ENV === "production") {
    logEvent(getFirebaseAnalytics(), firebaseEventName, params);

    const ReactPixel = require("react-facebook-pixel").default;
    ReactPixel.track(pixelEventName, params);
  } else {
    console.log(
      `%c [Analytics]: ${firebaseEventName}:${pixelEventName}`,
      "background: #fff, font-weight: bold",
      params
    );
  }
};

// TODO: 해당 처리 모두 이전 필요. 일단은 여기서 처리
export const convertMeetupToMixpanelProperties = (meetup: Meetup) => {
  const tagsToTrack = mapKeys(meetup.tags, (value, key) => `meetup_tag_${key}`);
  return {
    meetup_attendee_count: meetup.attendeeCount,
    meetup_brief_location: meetup.briefLocation,
    meetup_closing_date: meetup.closingDate,
    meetup_created_at: meetup.createdAt,
    meetup_discount_price: meetup.discountPrice,
    meetup_host: meetup.host,
    meetup_id: meetup.id,
    meetup_max_capacity: meetup.maxCapacity,
    meetup_min_capacity: meetup.minCapacity,
    meetup_opening_date: meetup.openingDate,
    meetup_price: meetup.price ?? 0,
    meetup_sessions: meetup.sessions,
    meetup_thumbnail_url: meetup.thumbnailUrl,
    meetup_title: meetup.title,
    meetup_type: convertMeetupTypeToEng(meetup.type),
    ...tagsToTrack
  };
};
