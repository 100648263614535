import { BaseApi } from "./Api/BaseApi";

export const COOKIE_STORAGE_LIMIT = 4096;
export const COOKIE_DOMAIN_COUNT_LIMIT = 50;

export const getStoredCookieInfo = () => {
  const cookies = document.cookie.split(';');
  let size = 0;
  let count = 0;
  cookies.forEach((cookie) => {
    size += cookie.split('=')[0].length + cookie.split('=')[1].length;
    count += 1;
  });
  return { size, count };
};

export const checkCookieLimitOnDevelopment = (cookieValue: string) => {
  const storedCookieInfo = getStoredCookieInfo();
  const newCookieSize = new TextEncoder().encode(cookieValue).length;
  if (storedCookieInfo.size + newCookieSize > COOKIE_STORAGE_LIMIT) {
    BaseApi.logMessage('Cookie size limit exceeded', {
      storedCookieSize: storedCookieInfo.size,
      newCookieSize,
    });
  } else if (storedCookieInfo.count + 1 > COOKIE_DOMAIN_COUNT_LIMIT) {
    BaseApi.logMessage('Cookie domain count limit exceeded', {
      storedCookieCount: storedCookieInfo.count,
    });
  }
};
