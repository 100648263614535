export class ApplicationError extends Error {
  constructor(error: Error | string) {
    if (error instanceof Error) {
      super(error.message);
      this.stack = error.stack;
    } else {
      super(error);
    }
  }
}

export class RefreshTokenExpiredError extends ApplicationError {
  constructor(error?: Error | string) {
    super(error || 'refresh token is expired');
  }
}
