import React from 'react';

interface TagIconProps {
  color: string;
  size: number;
}

export const TagAll = ({ color, size = 20 }: TagIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.46828 15L7.83005 12.8795H4.63886L4.00063 15H2L5.09299 6.05661H7.36364L10.4689 15H8.46828ZM7.38819 11.2954L6.74995 9.22484C6.70904 9.08348 6.65585 8.90469 6.59039 8.68849C6.52493 8.47228 6.45947 8.25192 6.39401 8.0274C6.32855 7.80288 6.27537 7.60746 6.23446 7.44115C6.19354 7.60746 6.13626 7.81535 6.06262 8.06482C5.99716 8.30597 5.9317 8.53881 5.86624 8.76333C5.80896 8.97953 5.76396 9.13337 5.73123 9.22484L5.10527 11.2954H7.38819Z"
      fill={color}
    />
    <path d="M13.2603 15H11.4315V6H13.2603V15Z" fill={color} />
    <path d="M17 15H15.1712V6H17V15Z" fill={color} />
  </svg>
);

export const TagLove = ({ color, size = 20 }: TagIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.0713 5C12.0638 5 11.0237 5.48199 10.2925 6.27978C10.1788 6.39612 10.0813 6.49585 10 6.59557C9.91875 6.49585 9.82125 6.39612 9.7075 6.27978C8.97625 5.48199 7.93625 5 6.92875 5C4.97875 5 3.5 6.49584 3.5 8.50693C3.5 10.4848 4.3775 11.482 5.82375 12.9612C7.61125 14.7895 9.61 16.651 9.62625 16.6676C9.82776 16.855 10.1397 16.855 10.3412 16.6676C10.3575 16.651 12.3562 14.7895 14.1438 12.9612C15.6225 11.482 16.5 10.4848 16.5 8.50693C16.5 6.49584 15.0213 5 13.0713 5Z"
      fill={color}
    />
  </svg>
);

export const TagMovie = ({ color, size = 20 }: TagIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.543 11.3609L7.50873 16.1085C6.84211 16.5024 6 16.0219 6 15.2476V5.75245C6 4.97815 6.84211 4.49761 7.50873 4.89152L15.543 9.63907C16.1981 10.0261 16.1981 10.9739 15.543 11.3609Z"
      fill={color}
    />
  </svg>
);

export const TagCareer = ({ color, size = 20 }: TagIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 7.95652C4 7.3322 4.48502 6.82609 5.08333 6.82609H15.9167C16.515 6.82609 17 7.3322 17 7.95652V15.8696C17 16.4939 16.515 17 15.9167 17H5.08333C4.48502 17 4 16.4939 4 15.8696V7.95652Z"
      fill={color}
    />
    <path
      d="M12.6667 5.13043H8.33333V6.82609H7.25V5.13043C7.25 4.50611 7.73502 4 8.33333 4H12.6667C13.265 4 13.75 4.50611 13.75 5.13043V6.82609H12.6667V5.13043Z"
      fill={color}
    />
  </svg>
);

export const TagLifestyle = ({ color, size = 20 }: TagIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.1665 12.5577C12.1665 15.6163 10.8454 18.0958 8.45227 18.0958C6.05909 18.0958 3.5 14.9347 3.5 11.8761C3.5 8.81743 5.44006 7.01953 7.83324 7.01953C10.2264 7.01953 12.1665 9.49905 12.1665 12.5577Z"
      fill={color}
    />
    <path
      d="M7.83353 12.5577C7.83353 15.6163 9.15455 18.0958 11.5477 18.0958C13.9409 18.0958 16.5 14.9347 16.5 11.8761C16.5 8.81743 14.5599 7.01953 12.1668 7.01953C9.77358 7.01953 7.83353 9.49905 7.83353 12.5577Z"
      fill={color}
    />
    <path
      d="M9.49242 3.75728C10.1684 4.55313 10.0092 5.77795 9.92995 6.19325C9.91362 6.27881 9.83603 6.33751 9.74399 6.33394C9.29727 6.31662 7.99595 6.19686 7.31995 5.40102C6.64396 4.60517 6.80317 3.38035 6.88242 2.96505C6.89875 2.87949 6.97634 2.82079 7.06838 2.82436C7.51511 2.84168 8.81642 2.96144 9.49242 3.75728Z"
      fill={color}
    />
  </svg>
);

export const TagPhilosophy = ({ color, size = 20 }: TagIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0628 13.4432C12.3406 12.1105 13.2461 11.0072 13.9114 9.82406C14.2859 9.15797 14.5 8.38694 14.5 7.56522C14.5 5.04393 12.4848 3 10 3C7.51521 3 5.5 5.04393 5.5 7.56522C5.5 8.38694 5.71406 9.15797 6.08861 9.82406C6.75392 11.0072 7.65943 12.1105 7.93724 13.4432L7.96361 13.5697C8.02646 13.8712 8.28877 14.087 8.59255 14.087H11.4074C11.7112 14.087 11.9735 13.8712 12.0364 13.5697L12.0628 13.4432Z"
      fill={color}
    />
    <path
      d="M8.07143 15.3913C8.07143 15.0311 8.35925 14.7391 8.71429 14.7391H11.2857C11.6408 14.7391 11.9286 15.0311 11.9286 15.3913V16.6957C11.9286 17.0558 11.6408 17.3478 11.2857 17.3478H8.71429C8.35925 17.3478 8.07143 17.0558 8.07143 16.6957V15.3913Z"
      fill={color}
    />
  </svg>
);

export const TagMusic = ({ color, size = 20 }: TagIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 3.00778C5.55853 2.78751 4.39073 7.29716 4.31035 9.49987C4.31035 9.49987 2.5 9.22903 2.5 11.9374C2.58621 13.5625 3.01724 16 5.08621 16C5.51724 16 6.37931 14.9607 6.37931 12.7499C6.37931 9.462 5.41379 9.61593 5.41379 9.61593C5.70908 4.9271 8.31034 4.10108 10 4.10108C11.6897 4.10108 14.2909 4.9271 14.5862 9.61593C14.5862 9.61593 13.6207 9.462 13.6207 12.7499C13.6207 14.9607 14.4828 16 14.9138 16C16.9828 16 17.4138 13.5625 17.5 11.9374C17.5 9.22903 15.6897 9.49987 15.6897 9.49987C15.6093 7.29716 14.4415 2.78751 10 3.00778Z"
      fill={color}
    />
  </svg>
);

export const TagRelationship = ({ color, size = 20 }: TagIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.76894 6.00722C7.06034 4.67467 7.91771 3.69905 8.73864 3.34214C9.60824 2.96407 10.2936 2.99389 10.5 3.00608C10.7064 2.99389 11.3918 2.96407 12.2614 3.34214C13.0823 3.69905 13.9397 4.67467 14.2311 6.00722C14.3403 6.5069 14.2863 7.78392 14.0674 8.00601C14.6148 7.50631 14.948 8.17223 14.7838 8.67227C14.568 9.32953 14.1271 10.1624 13.7987 10.1624C13.3903 11.4054 13.0947 11.7322 12.5973 12.2824C12.4948 12.3958 12.3837 12.5186 12.2614 12.6609C12.0972 12.9385 12.1957 13.7602 12.5897 14.16C12.7206 14.2927 12.7941 14.2965 13.0326 14.3087C13.1514 14.3148 13.3111 14.323 13.5393 14.3503C14.5773 14.4744 15.9038 14.633 16.695 15.3349C17.2137 15.7951 17.8294 16.7666 17.9895 17.4849C18.056 17.783 17.7964 18 17.4951 18H3.50492C3.20361 18 2.94403 17.783 3.01048 17.4849C3.17057 16.7666 3.78631 15.7951 4.30499 15.3349C5.09616 14.633 6.42272 14.4744 7.46074 14.3503C7.68887 14.323 7.8486 14.3148 7.9674 14.3087C8.2059 14.2965 8.27942 14.2927 8.41027 14.16C8.80432 13.7602 8.90283 12.9385 8.73864 12.6609C8.61626 12.5186 8.5052 12.3958 8.40272 12.2824C7.90527 11.7322 7.60973 11.4054 7.20131 10.1624C6.87294 10.1624 6.432 9.32953 6.21619 8.67227C6.05201 8.17223 6.38515 7.50631 6.93258 8.00601C6.71366 7.78392 6.65967 6.5069 6.76894 6.00722Z"
      fill={color}
    />
  </svg>
);

export const TagWriting = ({ color, size = 20 }: TagIconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.44053 3.00223C8.59158 2.73271 9.00223 2.83994 9.00223 3.1489V8.84916C8.70409 9.02826 8.50446 9.3632 8.50446 9.74671C8.50446 10.3189 8.95038 10.7829 9.5 10.7829C10.0496 10.7829 10.4955 10.3189 10.4955 9.74671C10.4955 9.3632 10.2959 9.02826 9.99777 8.84916V3.1489C9.99777 2.83994 10.4084 2.73271 10.5595 3.00223L13.8192 8.81845C14.0253 9.18626 14.057 9.63247 13.905 10.0278L12.287 14.2368C12.287 14.2368 13.4822 14.2368 13.4822 15.6184C13.4822 17 12.1548 17 12.1548 17H6.84523C6.84523 17 5.51784 17 5.51784 15.6184C5.51784 14.2369 6.71301 14.2368 6.71301 14.2368L5.09499 10.0278C4.94301 9.63247 4.97468 9.18626 5.18082 8.81845L8.44053 3.00223Z"
      fill={color}
    />
  </svg>
);

export const TagEvent = ({ color, size = 20 }: TagIconProps) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 9C4 8.44772 4.44772 8 5 8H25C25.5523 8 26 8.44772 26 9V12.7C26 12.8657 25.8657 13 25.7 13C25.6278 13 25.5556 13 25.5 13C24.1193 13 23 14.1193 23 15.5C23 16.8807 24.1193 18 25.5 18C25.5556 18 25.6278 18 25.7 18C25.8657 18 26 18.1343 26 18.3V22C26 22.5523 25.5523 23 25 23H5C4.44772 23 4 22.5523 4 22V18.3C4 18.1343 4.13431 18 4.3 18C4.37218 18 4.44439 18 4.5 18C5.88071 18 7 16.8807 7 15.5C7 14.1193 5.88071 13 4.5 13C4.44439 13 4.37218 13 4.3 13C4.13431 13 4 12.8657 4 12.7V9Z"
      fill="#FFB549"
    />
    <rect x="18" y="16" width="1" height="3" fill="white" />
    <rect x="18" y="11" width="1" height="3" fill="white" />
    <rect x="18" y="8" width="1" height="1" fill="white" />
    <rect x="18" y="21" width="1" height="2" fill="white" />
  </svg>
);
