/* eslint-disable no-restricted-syntax */
import {
  Attendance,
  AttendanceType,
  AuthToken,
  Gender,
  Meetup,
  MeetupType,
  MembershipBenefit,
  WishType,
  Session,
  Prompt,
  AnswersResponse,
  MeetupWithWishes,
} from 'types/interface';
import {
  dayjsKr,
  formatKSTDate,
  getDayOfWeek,
  getNonMemberOpeningDate,
  getRemainingDays,
  isPast,
} from 'utils/TextUtils';
import {
  FilterTypeNames,
  MeetupTypeNames,
  AfterLoginActionKeys,
  TagType,
  WishedMeetupOrderType,
  REGION_TAG_ASSETS_MAP,
  TabStatus,
  NetworksType,
  DONGHAENG_GATHERING_HOST_ID,
} from 'constants/Constants';
import { WishApi } from 'utils/Api/WishApi';
import _ from 'lodash';
import { AnswerWithClapState } from 'src/pages/community/salons/[meetupId]/sessions/[sessionId]';

export const getMeetupSessionsTerm = (sessions: Session[]) => {
  const diff = dayjsKr(sessions[1].date).diff(sessions[0].date, 'day');
  for (let i = 2; i < sessions.length; i++) {
    const currentDiff = dayjsKr(sessions[i].date).diff(sessions[i - 1].date, 'day');
    if (currentDiff !== diff) {
      return '';
    }
  }
  return `(${diff / 7}주 간격)`;
};

export const isMeetupImminentToSoldOut = (meetup: Meetup) => {
  switch (meetup.type) {
    case MeetupType.SALON:
      return meetup.attendeeCount / meetup.maxCapacity > 0.5;
    case MeetupType.EVENT:
      return (
        meetup.minCapacity < meetup.attendeeCount && meetup.attendeeCount / meetup.maxCapacity > 0.5
      );
    case MeetupType.GATHERING:
      return (
        meetup.minCapacity < meetup.attendeeCount &&
        meetup.attendeeCount / meetup.maxCapacity >= 0.6
      );
    default:
      throw new Error('Wrong meetup type');
  }
};

const IMMINENT_NUMBER_BAR = 2;
export const meetupImminentText = (meetup: Meetup) => {
  const leftSeatCount = meetup.maxCapacity - meetup.attendeeCount;
  if (leftSeatCount <= IMMINENT_NUMBER_BAR && leftSeatCount > 0) {
    return `${leftSeatCount}자리 남음`;
  }
  return '마감 임박';
};

export const isMeetupTrending = (meetup: Meetup) =>
  meetup.openingDate &&
  dayjsKr(meetup.openingDate) <= dayjsKr() &&
  dayjsKr(meetup.openingDate).format('YYYY-MM-DD') === dayjsKr().format('YYYY-MM-DD') &&
  meetup.maxCapacity &&
  meetup.attendeeCount / meetup.maxCapacity > 0.16; // 12명 모임 중 2명 이상

export const isMeetupNew = (meetup: Meetup) => {
  const createdAt = meetup.openingDate ?? meetup.releasedAt;
  const dayDiff = dayjsKr().diff(createdAt, 'day');

  switch (meetup.type) {
    case MeetupType.SALON:
      return dayDiff <= 14;
    case MeetupType.EVENT:
      return dayDiff <= 10;
    case MeetupType.GATHERING:
      return dayDiff <= 5;
    default:
      throw new Error('Wrong meetup type');
  }
};

export const isMeetupBeforeOpening = (meetup: Meetup, membershipBenefit?: MembershipBenefit) => {
  // 오픈 일정이 있는 경우
  if (meetup?.openingDate) {
    const today = new Date();
    if (today < dayjsKr(meetup.openingDate).toDate()) {
      return true;
    }
    // 이벤트인 경우, 비멤버 오픈 일정 확인
    if (
      membershipBenefit &&
      meetup.type === MeetupType.EVENT &&
      !membershipBenefit?.discountPriceAvailable &&
      today < getNonMemberOpeningDate(meetup.openingDate).toDate()
    ) {
      return true;
    }
  }
  return false;
};

export const isMeetupClosed = (meetup: Meetup) => {
  const today = new Date();
  if (meetup?.closingDate && today >= dayjsKr(meetup.closingDate).toDate()) {
    return true;
  }
  return (
    meetup.sessions &&
    meetup.sessions.length > 0 &&
    today >= dayjsKr(meetup.sessions[0].date).toDate()
  );
};

export const isMeetupTypeDisplayable = (meetup: Meetup) =>
  [MeetupType.SALON, MeetupType.EVENT, MeetupType.GATHERING].includes(meetup.type);

export const isMeetupSoldOut = (meetup: Meetup) =>
  meetup.maxCapacity <= meetup.attendeeCount ||
  isPast(meetup.closingDate) ||
  isPast(meetup.sessions[0]?.date);

export const isMeetupSoldOutForFemale = (meetup: Meetup, userGender: string) => {
  if (
    meetup.femaleCapacity !== undefined &&
    meetup.femaleCapacity !== null &&
    userGender === Gender.FEMALE
  ) {
    return meetup.femaleCount >= meetup.femaleCapacity;
  }
  return false;
};

export const isMeetupSoldOutForMale = (meetup: Meetup, userGender: string) => {
  if (
    meetup.maleCapacity !== undefined &&
    meetup.maleCapacity !== null &&
    userGender === Gender.MALE
  ) {
    return meetup.maleCount >= meetup.maleCapacity;
  }
  return false;
};

export const isAllMeetupSoldOutForMale = (groupMeetupMembers: Meetup[], userGender: string) => {
  let soldOutCount = 0;
  groupMeetupMembers.forEach(meetup => {
    if (isMeetupSoldOutForMale(meetup, userGender)) {
      soldOutCount += 1;
    }
  });
  return soldOutCount === groupMeetupMembers.length;
};

export const isAnyMeetupImmediateToSoldOut = (groupMeetupMembers: Meetup[]) =>
  groupMeetupMembers.some(member => isMeetupImminentToSoldOut(member));

export const isAllMeetupSoldOutForFemale = (groupMeetupMembers: Meetup[], userGender: string) => {
  let soldOutCount = 0;
  groupMeetupMembers.forEach(meetup => {
    if (isMeetupSoldOutForFemale(meetup, userGender)) {
      soldOutCount += 1;
    }
  });
  return soldOutCount === groupMeetupMembers.length;
};

export const isAllMeetupSoldOut = (groupMeetupMembers: Meetup[]) => {
  let soldOutCount = 0;
  groupMeetupMembers.forEach(meetup => {
    if (isMeetupSoldOut(meetup)) {
      soldOutCount += 1;
    }
  });
  return soldOutCount === groupMeetupMembers.length;
};

export const MIN_REQUIREMENT_WISH_COUNT_FOR_OPENING = 20;
export const isSalonMeetupExceededMinRequirementForOpening = (meetup: Meetup) =>
  meetup.type === MeetupType.SALON &&
  meetup.openingDate &&
  meetup.openNotificationWishCount >= MIN_REQUIREMENT_WISH_COUNT_FOR_OPENING;

export const isAttendingMeetup = (meetup: Meetup, attendances: Attendance[]) =>
  attendances.some(attendance => attendance.meetup.id === meetup.id);

export const isAttendingAllMeetups = (groupMeetupMembers: Meetup[], attendances: Attendance[]) => {
  let attendanceCount = 0;
  groupMeetupMembers.forEach(meetup => {
    if (isAttendingMeetup(meetup, attendances)) {
      attendanceCount += 1;
    }
  });
  return attendanceCount === groupMeetupMembers.length;
};

export const isLastSession = (meetup: Meetup, session: Session) =>
  meetup.sessions.reduce((prev, current) => (prev.order > current.order ? prev : current)).id ===
  session.id;

export const searchMeetup = (query: string, meetup: Meetup) => {
  // search meetup by title, host name, host introduction, description, tags
  if (meetup.title.includes(query)) {
    return true;
  }
  if (meetup.host?.name.includes(query)) {
    return true;
  }
  if (meetup.host?.title?.includes(query)) {
    return true;
  }
  if (meetup.briefLocation.includes(query)) {
    return true;
  }
  if (meetup.sessions[0]?.place?.name.includes(query)) {
    return true;
  }
  if (meetup.sessions[0]?.place?.address.includes(query)) {
    return true;
  }
  if (meetup.sessions[0]?.date && getDayOfWeek(meetup.sessions[0].date).includes(query)) {
    return true;
  }
  if (meetup.sessions[0]?.date && formatKSTDate(meetup.sessions[0].date).includes(query)) {
    return true;
  }
  if (meetup.tags[TagType.SALON_CATEGORY][0]?.includes(query)) {
    return true;
  }
  return false;
};

export const meetupTypeStringToNum = (type?: string) => {
  // search meetup by title, host name, host introduction, description, tags
  if (type === MeetupTypeNames.SALON) {
    return [MeetupType.SALON];
  }
  if (type === MeetupTypeNames.EVENT) {
    return [MeetupType.EVENT];
  }
  if (type === MeetupTypeNames.GATHERING) {
    return [MeetupType.GATHERING];
  }
  if (type === MeetupTypeNames.ALL) {
    return [MeetupType.SALON, MeetupType.EVENT, MeetupType.GATHERING];
  }
  return [MeetupType.SALON, MeetupType.EVENT, MeetupType.GATHERING];
};

export const meetupTypeNumToString = (type?: number) => {
  // search meetup by title, host name, host introduction, description, tags
  if (type === MeetupType.SALON) {
    return MeetupTypeNames.SALON;
  }
  if (type === MeetupType.EVENT) {
    return MeetupTypeNames.EVENT;
  }
  if (type === MeetupType.GATHERING) {
    return MeetupTypeNames.GATHERING;
  }
};

export const networksTypeStringToMeetupType = (type?: string) => {
  if (type === NetworksType.SALON_MEMBERS) {
    return [MeetupType.SALON];
  }
  if (type === NetworksType.EVENT_MEMBERS) {
    return [MeetupType.EVENT];
  }
  if (type === NetworksType.GATHERING_MEMBERS) {
    return [MeetupType.GATHERING];
  }
  if (type === NetworksType.ALL) {
    return [MeetupType.SALON, MeetupType.EVENT, MeetupType.GATHERING];
  }
  return [MeetupType.SALON, MeetupType.EVENT, MeetupType.GATHERING];
};

export const networksTypeToMeetupTypeKr = (type?: string) => {
  if (type === NetworksType.SALON_MEMBERS) {
    return '정기 모임';
  }
  if (type === NetworksType.EVENT_MEMBERS) {
    return '이벤트';
  }
  if (type === NetworksType.GATHERING_MEMBERS) {
    return '소모임';
  }
  return '';
};

export const meetupTypeNumToHostTypeKr = (type?: number) => {
  // search meetup by title, host name, host introduction, description, tags
  if (type === MeetupType.GATHERING) {
    return '소모임장';
  }
  return '모임장';
};

export const convertMeetupTypeToEng = (type: number) => {
  switch (type) {
    case MeetupType.SALON:
      return 'salon';
    case MeetupType.EVENT:
      return 'event';
    case MeetupType.GATHERING:
      return 'gathering';
    case MeetupType.RENTAL:
      return 'rental';
    default:
      throw new Error('invalid meetup type');
  }
};

export const filterTypeEngTokr = (type?: string) => {
  // search meetup by title, host name, host introduction, description, tags
  if (type === WishedMeetupOrderType.WISH_DESC) {
    return FilterTypeNames.ORDER_BY_WISH_DESC;
  }
  if (type === WishedMeetupOrderType.SESSION_ASC) {
    return FilterTypeNames.ORDER_BY_SESSION_ASC;
  }
};

export const filterTypeKrToEng = (type?: string) => {
  // search meetup by title, host name, host introduction, description, tags
  if (type === FilterTypeNames.ORDER_BY_WISH_DESC) {
    return WishedMeetupOrderType.WISH_DESC;
  }
  if (type === FilterTypeNames.ORDER_BY_SESSION_ASC) {
    return WishedMeetupOrderType.SESSION_ASC;
  }
};

export const meetupInfoTitle = (meetup: Meetup) => {
  if (meetup.type === MeetupType.SALON && !!meetup.host?.nickname) {
    return `${meetup.host.nickname} | ${meetup.title}`;
  }
  return meetup.title;
};

const IMAGE_VARIANTS = ['public'];
export const BASE_IMAGE_CDN_URL = 'https://imagedelivery.net/hftuYAvwaYr78lZIcGkPyQ/';
export const BASE_NOTION_IMAGE_CDN_URL = 'https://www.notion.so/image/';

const resizeImage = (src: string, options: { width?: number; quality?: number }) => {
  if (src.startsWith(BASE_IMAGE_CDN_URL)) {
    const srcArr = src.split('/');
    const basePath = srcArr.slice(0, srcArr.length - 1).join('/');
    const variant = srcArr[srcArr.length - 1];
    if (IMAGE_VARIANTS.includes(variant)) {
      const conditions = [];
      if (options.width) {
        conditions.push(`w=${options.width}`);
      } else if (options.quality) {
        conditions.push(`quality=${options.quality}`);
      }
      const dynamicVariant = conditions.length > 0 ? conditions.join(',') : IMAGE_VARIANTS[0];
      return `${basePath}/${dynamicVariant}`;
    }
  } else if (src.startsWith(BASE_NOTION_IMAGE_CDN_URL)) {
    if (options.width) {
      return `${src}&width=${options.width}`;
    }
  }
  return src;
};

export const meetupThumbnailImgSrc = (meetup: Meetup) => {
  if (meetup?.type === MeetupType.SALON) {
    return meetup?.additionalInformation && meetup?.additionalInformation?.memberLed
      ? meetup?.thumbnailUrl
      : meetup?.host?.profileImageUrl ?? '';
  }
  return meetup?.thumbnailUrl;
};

export const getResizedMeetupThumbnailImage = (
  meetup: Meetup,
  width: number[], // [mobile, desktop]
  isMobile = false,
) => {
  const imageSrc = meetupThumbnailImgSrc(meetup);
  if (!imageSrc) {
    return '';
  }

  const defaultWidth = width.pop();
  const mobileWidth = width.pop() ?? defaultWidth;
  const tempNum = 2; // 직사각형 이미지일 경우 대비 2배로 늘려줌
  if (isMobile) {
    return resizeImage(imageSrc, { width: mobileWidth * tempNum });
  }
  return resizeImage(imageSrc, { width: defaultWidth * tempNum });
};

/*
export const meetupThumbnailImgSrc = (meetup: Meetup) => {
  if (meetup.type === MeetupType.SALON) {
    return meetup.additionalInformation && meetup.additionalInformation?.memberLed
      ? resizeImage(meetup.thumbnailUrl, { width: '110' })
      : resizeImage(meetup.host?.profileImageUrl, { width: '110' }) || '';
  }
  if (meetup.type === MeetupType.GATHERING) {
    return resizeImage(meetup.thumbnailUrl, { width: '240' });
  }
  return resizeImage(meetup.thumbnailUrl, { width: '490' });
};
 */

export const calculateMeetupRefundRate = (
  meetupType: number,
  attendanceType: number,
  daysLeft: number,
  purchaseDate: string,
) => {
  if (dayjsKr().diff(dayjsKr(purchaseDate), 'hour') <= 6) {
    return 1.0;
  }
  if (attendanceType === AttendanceType.REGISTER) {
    switch (meetupType) {
      case MeetupType.SALON:
        if (daysLeft >= 14) {
          return 1.0;
        }
        if (daysLeft >= 7) {
          return 0.8;
        }
        if (daysLeft >= 5) {
          return 0.5;
        }
        return 0;
      case MeetupType.EVENT:
        if (daysLeft >= 5) {
          return 1.0;
        }
        if (daysLeft >= 3) {
          return 0.5;
        }
        return 0;
      case MeetupType.GATHERING:
        if (daysLeft >= 7) {
          return 1.0;
        }
        if (daysLeft >= 3) {
          return 0.8;
        }
        if (daysLeft >= 1) {
          return 0.5;
        }
        return 0;
      default:
        throw new Error('Wrong meetup type!');
    }
  } else if (attendanceType === AttendanceType.VISIT) {
    switch (meetupType) {
      case MeetupType.SALON:
        if (daysLeft >= 3) {
          return 1.0;
        }
        return 0;
      case MeetupType.EVENT:
      case MeetupType.GATHERING:
      default:
        throw new Error('Wrong meetup type!');
    }
  } else {
    throw new Error('Wrong attendance type!');
  }
};

export const checkHasRefundFee = (meetupType: number, daysLeft: number) => {
  switch (meetupType) {
    case MeetupType.SALON:
      if (daysLeft < 14) {
        return true;
      }
      return false;
    case MeetupType.EVENT:
      if (daysLeft < 5) {
        return true;
      }
      return false;
    case MeetupType.GATHERING:
      if (daysLeft < 7) {
        return true;
      }
      return false;
    default:
      throw new Error('Wrong meetup type!');
  }
};

export const calculateMeetupRefundAmount = (attendance: Attendance) => {
  if (!attendance.payment || attendance.payment.totalAmount === 0) return 0;
  return (
    attendance.payment.totalAmount *
    calculateMeetupRefundRate(
      attendance.meetup.type,
      attendance.type,
      getRemainingDays(attendance.meetup.sessions[0].date),
      attendance.createdAt,
    )
  );
};

export const RefundInfoText = (
  attendance: Attendance, 
  visitDate: string,
  membershipExpirationDate?: string,
) => {
  const remainingDays = getRemainingDays(visitDate);
  const remainingDaysKr = remainingDays === 0 ? '모임 당일' : `${remainingDays}일 전`;
  const isPurchasedIn6Hours = dayjsKr().diff(attendance.createdAt, 'hour') <= 6;
  const isMembershipExpired = membershipExpirationDate && dayjsKr().isAfter(dayjsKr(membershipExpirationDate));
  const hasRefundFee = checkHasRefundFee(attendance.meetup.type, remainingDays);
  if (attendance.type === AttendanceType.VISIT && attendance.meetup.type === MeetupType.SALON) {
    const isVisitTicketExpired = remainingDays < 3 || isMembershipExpired;
    return `지금(${remainingDaysKr}) 놀러가기 참여를 취소하면,<br>
    ${
      attendance.payment && attendance.payment?.totalAmount > 0
        ? `놀러가기 티켓 <b>1개</b>가 ${
            isVisitTicketExpired ? '소멸' : '복구'
          }되며<br>재료비 <b>${attendance.payment?.totalAmount.toLocaleString()}원</b>은 ${
            isVisitTicketExpired ? '환불이 불가합니다.' : '환불됩니다.'
          }${isMembershipExpired ? '<br><b>(소멸 사유: 멤버십 만료)</b>' : ''}`
        : `놀러가기 티켓 <b>1개</b>가 ${isVisitTicketExpired ? '소멸' : '복구'}됩니다.
          ${isMembershipExpired ? '<br><b>(소멸 사유: 멤버십 만료)</b>' : ''}`
    }<br><br>
    <a href="https://7pictures.notion.site/FAQ-3b14a5ffcd184dd2934dd8c4bb3b2e8c#3c172734b7814a26bebb14a9b34fddea" 
    target="_blank">놀러가기 취소 규정 자세히 보기</a>`;
  }
  if (attendance.type === AttendanceType.REGISTER) {
    if (isPurchasedIn6Hours && hasRefundFee) {
      return `해당 ${meetupTypeNumToString(
        attendance.meetup.type,
      )} 참여를 지금(${remainingDaysKr}) 취소할 시, 환불 규정에 따라 취소 수수료가 발생하나 결제 후 6시간 이내에는 100%가 환불됩니다.<br><br>
    <b>*최종 환불 금액: ${calculateMeetupRefundAmount(attendance).toLocaleString()}원</b><br><br>
    <a href="https://7pictures.notion.site/FAQ-3b14a5ffcd184dd2934dd8c4bb3b2e8c#ee34f6d40a9543fab91b3d9d5162bd1b" 
    target="_blank">환불 규정 자세히 보기</a>`;
    }
    return `해당 ${meetupTypeNumToString(
      attendance.meetup.type,
    )} 참여를 지금(${remainingDaysKr}) 취소할 시, 환불 규정에 따라 결제한 금액인
    ${attendance.payment?.totalAmount.toLocaleString() ?? 0}원의 
    ${
      100 *
      calculateMeetupRefundRate(
        attendance.meetup.type,
        attendance.type,
        remainingDays,
        attendance.createdAt,
      )
    }%가 환불됩니다.<br><br>
    <b>*최종 환불 금액: ${calculateMeetupRefundAmount(attendance).toLocaleString()}원</b><br><br>
    <a href="https://7pictures.notion.site/FAQ-3b14a5ffcd184dd2934dd8c4bb3b2e8c#ee34f6d40a9543fab91b3d9d5162bd1b" 
    target="_blank">환불 규정 자세히 보기</a>`;
  }
  return '';
};

/*
  redirectAction=open-notification/1,reopening/1,waitlist/1,bookmark/1
 */
export const handleAfterLoginAction = async (actionParams: string, authToken: AuthToken) => {
  const batchActions: {
    action:
      | AfterLoginActionKeys.OPEN_NOTIFICATION
      | AfterLoginActionKeys.BOOKMARK
      | AfterLoginActionKeys.WAITLIST
      | AfterLoginActionKeys.REOPENING
      | AfterLoginActionKeys.FOLLOW;
    targetId: string;
  }[] = [];

  const actionPairs = String(actionParams)?.split(',');
  actionPairs.forEach(pair => {
    const [key, value] = pair.split('/');
    batchActions.push({
      action: key as AfterLoginActionKeys,
      targetId: value,
    });
  });

  await Promise.all(
    batchActions.map(async ({ action, targetId }) => {
      let wishType;
      let meetupId;
      let hostId;
      switch (action) {
        case AfterLoginActionKeys.OPEN_NOTIFICATION:
          wishType = WishType.TYPE_OPEN_NOTIFICATION;
          meetupId = targetId;
          break;
        case AfterLoginActionKeys.BOOKMARK:
          wishType = WishType.TYPE_BOOKMARK;
          meetupId = targetId;
          break;
        case AfterLoginActionKeys.WAITLIST:
          wishType = WishType.TYPE_WAITLIST;
          meetupId = targetId;
          break;
        case AfterLoginActionKeys.REOPENING:
          wishType = WishType.TYPE_REOPENING;
          meetupId = targetId;
          break;
        case AfterLoginActionKeys.FOLLOW:
          wishType = WishType.TYPE_FOLLOW;
          hostId = targetId;
          break;
        default:
          wishType = undefined;
          break;
      }
      if (wishType !== undefined) {
        await WishApi.createWish(
          {
            hostId,
            meetupId,
            type: wishType,
          },
          authToken,
        );
      }
    }),
  );
};

export const getRegionsWithChildren = (regions: string[]) => {
  const regionSet: Set<string> = new Set(regions);
  for (const region of regions) {
    const tagAssets = REGION_TAG_ASSETS_MAP[region];
    if (tagAssets && tagAssets.children) {
      tagAssets.children.forEach(child => regionSet.add(child));
    }
  }
  return Array.from(regionSet);
};

export const isPartyTime = (currentTime: Date) => {
  const now = dayjsKr(currentTime);
  const hours = now.hour();
  const day = now.day();

  const isWednesday = day === 3; // Day 3 is Wednesday
  const isAfter11AM = hours >= 11;

  return isWednesday && isAfter11AM;
};

export const promptAndAnswersWithClapStateCSVData = (
  promptsAndAnswers?: {
    prompt: Prompt;
    answersWithClapState: AnswerWithClapState[];
  }[],
) => {
  if (!promptsAndAnswers || promptsAndAnswers.length === 0) return [];
  const data = [];

  const headerRow = ['이름'];
  for (const promptAndAnswer of promptsAndAnswers) {
    headerRow.push(promptAndAnswer.prompt.question);
  }
  data.push(headerRow);

  const processedUsers = new Set();

  for (const promptAndAnswer of promptsAndAnswers) {
    const answersWithClapState = Array.isArray(promptAndAnswer.answersWithClapState)
      ? promptAndAnswer.answersWithClapState
      : [];
    for (const answer of answersWithClapState) {
      const user = answer?.answer.user;
      if (user && !processedUsers.has(user.id)) {
        const answerRow = [];
        answerRow.push(user.name || '');
        for (const addPromptAndAnswer of promptsAndAnswers) {
          const answers = Array.isArray(addPromptAndAnswer.answersWithClapState)
            ? addPromptAndAnswer.answersWithClapState
            : [];
          const answerData = answers.find(
            a => a.answer.user.id === user.id,
          );
          answerRow.push(answerData?.answer.answerText || '');
        }
        data.push(answerRow);
        processedUsers.add(user.id);
      }
    }
  }

  return data;
};

export const getFilteredMeetupsByGroupId = (meetupWithWishes: MeetupWithWishes[]) => {
  const filteredMeetups = meetupWithWishes.filter(
    mw =>
      mw.meetup.additionalInformation?.group?.id.toString() === mw.meetup.id.toString() ||
      mw.meetup.additionalInformation === null,
  );
  return filteredMeetups;
};

export const wishTypeToAfterLoginAction = (wishType: WishType) => {
  switch (wishType) {
    case WishType.TYPE_OPEN_NOTIFICATION:
      return AfterLoginActionKeys.OPEN_NOTIFICATION;
    case WishType.TYPE_BOOKMARK:
      return AfterLoginActionKeys.BOOKMARK;
    case WishType.TYPE_WAITLIST:
      return AfterLoginActionKeys.WAITLIST;
    case WishType.TYPE_REOPENING:
      return AfterLoginActionKeys.REOPENING;
    case WishType.TYPE_FOLLOW:
      return AfterLoginActionKeys.FOLLOW;
    default:
      return undefined;
  }
};

export const wishButtonNoticeText = (wishType: WishType) => {
  switch (wishType) {
    case WishType.TYPE_OPEN_NOTIFICATION:
      return {
        success: '해당 모임이 오픈되면 알림톡으로 알려드릴게요!',
        title: '오픈 예정 알림',
      };
    case WishType.TYPE_WAITLIST:
      return {
        success: '빈자리가 생기면 알림톡으로 알려드릴게요!',
        title: '빈자리 알림',
      };
    case WishType.TYPE_REOPENING:
      return {
        success: '모임이 다시 열리면 알림톡으로 알려드릴게요!',
        title: '앵콜 알림',
      };
    case WishType.TYPE_FOLLOW:
      return {
        success: '새로운 개설 소식이 생기면 알려드릴게요! ',
        title: '팔로우',
      };
    case WishType.TYPE_NEW_PROMPT_NOTIFICATION:
      return {
        success: '회차별로 질문이 올라오면 가장 먼저 알려드릴게요! ',
        title: '질문 업로드 알림',
      };
    default:
      return undefined;
  }
};

export const tabStatusToKr = (tabStatus: TabStatus) => {
  switch (tabStatus) {
    case TabStatus.AVAILABLE:
      return '신청 가능';
    case TabStatus.BEFORE_OPENING:
      return '오픈 예정';
    case TabStatus.WAITLIST:
      return '대기 가능';
    default:
      return '';
  }
};

export const groupMeetupsByGroupId = (meetupWithWishes: MeetupWithWishes[]) => {
  // // GROUP 기준으로 묶어서 보여주기
  // // FIXME: 추후 group과 각 모임의 조건을 표현하기 위한 더 나은 프로토콜 필요, + 백엔드에서 직접 처리
  // //  필요한 뱃지나 표현을 적절히 섞을 수 있도록
  const groupIdMap = new Map<string, Meetup[]>();
  const groupRepresentativeMap = new Map<string, string>();
  meetupWithWishes.forEach(mw => {
    if (mw.meetup.additionalInformation?.group) {
      const groupId = mw.meetup.additionalInformation?.group?.id;
      const groupMeetups = groupIdMap.get(groupId);
      if (groupMeetups) {
        groupMeetups.push(mw.meetup);
      } else {
        groupIdMap.set(groupId, [mw.meetup]);
      }
    }
  });

  // group 모임 중 대표 모임을 선정
  groupIdMap.forEach((meetups, groupId) => {
    const orderedMeetups = _.sortBy(meetups, meetup => meetup.sessions[0].date);
    const representativeMeetup =
      orderedMeetups.find(meetup => !isPast(meetup.sessions[0]?.date)) ?? orderedMeetups[0];
    groupRepresentativeMap.set(groupId, representativeMeetup.id);
  });

  // 대표 모임을 기준으로 group 모임을 묶어서 보여주기
  // eslint-disable-next-line no-param-reassign
  meetupWithWishes = meetupWithWishes.filter(mw => {
    const groupId = mw.meetup.additionalInformation?.group?.id;
    if (groupId) {
      return groupRepresentativeMap.get(groupId) === mw.meetup.id;
    }
    return true;
  });

  return meetupWithWishes;
};

export const SPARE_SEAT_FOR_VISIT = 2
export const MAX_DEFAULT_SEAT_FOR_VISIT = 4
export const MAX_SEAT_FOR_VISIT = 7
export const getDefaultVisitCapacityForSalon = (meetup: Meetup) => {
  const adjustedVisitCapacity =
    meetup.maxCapacity + SPARE_SEAT_FOR_VISIT - meetup.attendeeCount

  return adjustedVisitCapacity > MAX_DEFAULT_SEAT_FOR_VISIT
    ? MAX_DEFAULT_SEAT_FOR_VISIT
    : adjustedVisitCapacity
}

export const getUpcomingSession = (meetup: Meetup) => {
  const upcomingSession = meetup.sessions.find(session => new Date(session.date) >= new Date());
  return upcomingSession;
}

export const isDonghaengGathering = (meetup: Meetup) => {
  return meetup.type === MeetupType.GATHERING && meetup.host && String(meetup.host.id) === DONGHAENG_GATHERING_HOST_ID;
};

export const isGatheringOpenChatUrlAvailable = (meetup: Meetup) => {
  const now = dayjsKr();
  return (isDonghaengGathering(meetup) && now.isAfter(dayjsKr(meetup.sessions[0].date).subtract(1, 'day').startOf('day'))) ||
    (!isDonghaengGathering(meetup) && now.isAfter(dayjsKr(meetup.sessions[0].date).subtract(2, 'day').startOf('day')))
}
