import { AppProps } from 'next/app';
import { GA_SCRIPT, IN_APP_ESCAPED_SCRIPT } from 'constants/Scripts';
import React, { useEffect } from 'react';
import '../styles/style.css';
import { useRouter } from 'next/router';
import { initAnalytics } from 'utils/Analytics';
import { ThemeProvider } from 'styled-components';
import { RecoilRoot } from 'recoil';
import { useScrollRestoration } from 'hooks/useScrollRestoration';
import NextNProgress from 'nextjs-progressbar';
import { Colors } from 'constants/Colors';
import Script from 'next/script';

// react-notion-x
import 'react-notion-x/src/styles.css'; // core styles shared by all of react-notion-x (required)

const breakpoints = ['1000px', '1000px', '1000px', '1000px'];

declare global {
  interface Window {
    Kakao: any;
  }
}

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useScrollRestoration(router);

  useEffect(() => {
    initAnalytics();
  }, [router.asPath]);

  return (
    <RecoilRoot>
      <ThemeProvider theme={{ breakpoints }}>
        <Script
          id="ga-init-script"
          src="https://www.googletagmanager.com/gtag/js?id=G-GLR8SDQ7FH"
          strategy="afterInteractive"
          async
        />
        <Script
          id="ga-tag-script"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{ __html: GA_SCRIPT }}
        />
        <Script
          id="inapp-escape-script"
          type="text/javascript"
          charSet="UTF-8"
          dangerouslySetInnerHTML={{
            __html: IN_APP_ESCAPED_SCRIPT,
          }}
        />
        <Script
          id="kakao-init"
          src="https://t1.kakaocdn.net/kakao_js_sdk/2.1.0/kakao.min.js"
          integrity="sha384-dpu02ieKC6NUeKFoGMOKz6102CLEWi9+5RQjWSV0ikYSFFd8M3Wp2reIcquJOemx"
          crossOrigin="anonymous"
          strategy="afterInteractive"
          onLoad={() => {
            if (window.Kakao?.isInitialized()) {
              return;
            }
            window.Kakao.init(process.env.KAKAO_JAVASCRIPT_KEY);
          }}
        />
        <NextNProgress color={Colors.gray11} height={1} showOnShallow={false} />
        <Component {...pageProps} />
      </ThemeProvider>
    </RecoilRoot>
  );
}

export default MyApp;
